// Lazy-loaded components
const Lobby = () => import('@/pages/public/LobbyPage.vue')

// Profile
const PlayerProfile = () => import('@/pages/user/ProfilePage.vue')
const ContactInfo = () => import('@/pages/user/ContactInfo.vue')
const AcceptWaiver = () => import('@/pages/user/AcceptWaiver.vue') 
const TeamSettings = () => import('@/pages/user/TeamSettings.vue')

// Dashboard
const Account = () => import('@/pages/dashboard/AccountPage.vue')
const Accounts = () => import('@/pages/dashboard/AccountsPage.vue')
//const ManagerReport = () => import('@/pages/dashboard/ManagerReportPage.vue')
const Dashboard = () => import('@/pages/dashboard/DashboardPage.vue')
const GameReport = () => import('@/pages/dashboard/GameReportPage.vue')
const GameReports = () => import('@/pages/dashboard/GameReportsPage.vue')
const Cards = () => import('@/pages/dashboard/CardsPage.vue')
const League = () => import('@/pages/dashboard/LeaguePage.vue')
const Leagues = () => import('@/pages/dashboard/LeaguesPage.vue')
const Managers = () => import('@/pages/dashboard/ManagersPage.vue')
const Player = () => import('@/pages/dashboard/PlayerPage.vue')
const Players = () => import('@/pages/dashboard/PlayersPage.vue')
//const Referee = () => import('@/pages/dashboard/RefereePage.vue')
const Referees = () => import('@/pages/dashboard/RefereesPage.vue')
const FreeAgents = () => import('@/pages/dashboard/FreeAgentsPage.vue')
const Team = () => import('@/pages/dashboard/TeamPage.vue')
const Teams = () => import('@/pages/dashboard/TeamsPage.vue')
const Users = () => import('@/pages/dashboard/UsersPage.vue')

// Registration
const Accept = () => import('@/pages/user/AcceptInvitePage.vue')

// Authentication
const Login = () => import('@/pages/public/LoginPage.vue')
const Signup = () => import('@/pages/public/SignupPage.vue')
const Logout = () => import('@/pages/user/LogoutPage.vue')
const ForgotPassword = () => import('@/pages/public/ForgotPassword.vue')
const ResetPassword = () => import('@/pages/user/ResetPassword.vue')

// Terms of Use
const TOU = () => import('@/pages/public/TOUPage.vue')

// Referee Report
const RefereeReport = () => import('@/pages/RefereeReportPage.vue')
const GameMode = () => import('@/pages/GameModePage.vue')

// Public components (not restricted to subdomains)
const NotFoundPage = () => import('@/pages/common/NotFoundPage.vue')


const routes = [
  {
    path: '/',
    name: 'lobby',
    components: {
      default: Lobby,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout'
    },
    beforeEnter(to, from) {
      // The sessionStorage is cleared on the subdomain when a user clicks 'log out' but the sessionStorage for
      // the root domain is not cleared - so we clear it here. This is not ideal because not all requests from '/'
      // are logout requests. We should improve the way sessionStorage is used so we can delete this.
      if (from.path === '/') {
        //console.log("Lobby.ts: clearCache - CJS - commented out")
        // CJS - sessionStorage should only be set when logging in and cleared when logging out
        //sessionStorage.clear()
      }
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: NotFoundPage,
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    components: {
      default: PlayerProfile,
    },
    meta: {
      //roles: ['ALL'],
      roles: ['player', 'referee', 'manager', 'admin', 'owner', 'engineer'],
      layout: 'LobbyLayout',
      forceRedirect: true
    },
  },
  {
    path: '/contact-info',
    name: 'contactInfo',
    components: {
      default: ContactInfo,
    },
    meta: {
      //roles: ['ALL'],
      roles: ['player', 'referee', 'manager', 'admin', 'owner', 'engineer'],
      layout: 'LobbyLayout',
      forceRedirect: true
    },
  },
  {
    path: '/accept-waiver',
    name: 'acceptWaiver',
    components: {
      default: AcceptWaiver,
    },
    meta: {
      //roles: ['ALL'],
      roles: ['player', 'referee', 'manager', 'admin', 'owner', 'engineer'],
      layout: 'LobbyLayout',
      forceRedirect: true
    },
  },
  {
    path: '/team-settings/:id',
    name: 'teamSettings',
    components: {
      default: TeamSettings,
    },
    meta: {
      roles: ['manager', 'admin', 'owner', 'engineer'],
      layout: 'LobbyLayout',
      forceRedirect: true
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      default: Dashboard,
    },
    meta: {
      roles: ['manager', 'admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/accounts',
    name: 'accounts',
    components: {
      default: Accounts,
    },
    meta: {
      roles: ['engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/accounts/:id',
    name: 'account',
    components: {
      default: Account,
    },
    meta: {
      roles: ['engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/leagues',
    name: 'leagues',
    components: {
      default: Leagues,
    },
    meta: {
      roles: ['engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/leagues/:id',
    name: 'league',
    components: {
      default: League,
    },
    meta: {
      roles: ['engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/teams',
    name: 'teams',
    components: {
      default: Teams,
    },
    meta: {
      roles: ['admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/teams/:id',
    name: 'team',
    components: {
      default: Team,
    },
    meta: {
      roles: ['admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/players/:id',
    name: 'player',
    components: {
      default: Player,
    },
    meta: {
      roles: ['manager', 'admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/managers',
    name: 'managers',
    components: {
      default: Managers,
    },
    meta: {
      roles: ['admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/players',
    name: 'players',
    components: {
      default: Players,
    },
    meta: {
      roles: ['manager', 'admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/users',
    name: 'users',
    components: {
      default: Users,
    },
    meta: {
      roles: ['engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/game-reports',
    name: 'gameReports',
    components: {
      default: GameReports,
    },
    meta: {
      roles: ['manager', 'admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/game-reports/:id',
    name: 'gameReport',
    components: {
      default: GameReport,
    },
    meta: {
      roles: ['manager', 'admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/cards',
    name: 'cards',
    components: {
      default: Cards,
    },
    meta: {
      roles: ['admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/referees',
    name: 'referees',
    components: {
      default: Referees,
    },
    meta: {
      roles: ['admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  {
    path: '/freeagents',
    name: 'freeagents',
    components: {
      default: FreeAgents,
    },
    meta: {
      roles: ['admin', 'owner', 'engineer'],
      layout: 'DashboardLayout'
    },
  },
  //{
  //  path: '/referees/:id',
  //  name: 'referee',
  //  components: {
  //    default: Referee,
  //  },
  //  meta: {
  //    roles: ['admin', 'owner', 'engineer'],
  //    layout: 'DashboardLayout'
  //  },
  //},
  {
    path: '/referee-report',
    name: 'refereeReport',
    components: {
      default: RefereeReport,
    },
    meta: {
      roles: ['referee', 'admin', 'owner', 'engineer'],
      layout: 'LobbyLayout'
    },
  },
   {
    path: '/game-mode/:id',
    name: 'gameMode',
    components: {
      default: GameMode,
    },
    meta: {
      roles: ['referee', 'admin', 'owner', 'engineer'],
      layout: 'LobbyLayout',
    },
  },
  // {
  //   path: '/manager-report',
  //   name: 'managerReport',
  //   components: {
  //     default: ManagerReport,
  //   },
  //   meta: {
  //     roles: ['manager', 'admin', 'owner', 'engineer'],
  //     layout: 'DashboardLayout'
  //   },
  // },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   components: {
  //     default: Dashboard,
  //   },
  //   meta: {
  //     roles: ['player', 'manager', 'admin', 'owner', 'engineer'],
  //     layout: 'DashboardLayout'
  //   },
  // },

  {
    path: '/login',
    name: 'login',
    components: {
      default: Login,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout',
      forceRedirect: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      default: Logout,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout',
      forceRedirect: true,
    },
  },  
  {
    path: '/users/password/new',
    name: 'forgotPassword',
    components: {
      default: ForgotPassword,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout',
      forceRedirect: true,
    },
  },
  {
    path: '/users/password/edit',
    name: 'resetPassword',
    components: {
      default: ResetPassword,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout',
      forceRedirect: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    components: {
      default: Signup,
    },
    meta: {
      roles: ['engineer'],
      layout: 'LobbyLayout',
      forceRedirect: true,
    },
  },
  {
    path: '/users/invitation/accept',
    name: 'acceptInvite',
    components: {
      default: Accept,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout',
      forceRedirect: true
    },
  },
    {
    path: '/terms-of-use',
    name: 'terms-of-use',
    components: {
      default: TOU,
    },
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout',
      forceRedirect: true
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: NotFoundPage,
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout'
    }
  }
]

export default routes
